<template>
  <v-card class="custom-field-popup-card">
    <v-card-text class="pa-4">
      <div class="success-alert d-flex flex-column align-center">
        <v-icon color="#2b84eb" class="mb-4 mt-2" size="32px">
          mdi-check-circle
        </v-icon>
        <div class="success-alert__title mb-1">
          Success!
        </div>
        <div v-if="!message" class="success-alert__subtitle mb-6">
          {{ `Successfully imported ${number_of_contacts || ''} contacts` }}
        </div>
        <div v-else v-text="message" />
        <v-btn
          height="34px"
          class="custom-button custom-button--blue"
          block
          @click="$emit('dismiss')"
        >
          Dismiss
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SuccessfulImportAlert",
  props: {
    number_of_contacts: {
      default: () => {},
    },
    message: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.success-alert {
  &__title {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #241c15;
  }
  &__subtitle {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #66788e;
  }
}
</style>
