<template>
  <v-container class="mt-3 px-sm-10 px-3" fluid>
    <PageHeader
      header-text="Contact Match Tool"
      class="page-header-block"
    >
      <template #subheader>
        <div>
          Import a file and find matching contacts that already exist for the account
        </div>
      </template>
    </PageHeader>

    <v-row>
      <v-col cols="12">
        <p style="font-size: 14px;">
          This contact match tool can be used to find matches for contact data in the uploaded file by searching this
          account's database. Unique identifiers will be used to find matches. If you select to perform fuzzy matching,
          it will only be done if a match with a unique identifier does not result in matches. Fuzzy matching will result
          in a match score from 0-100. The closer the match score is to 100, the closer the match is. Fuzzy matching will
          use the name fields (first and last) in combination with location data (if available) to calculate the match
          score.
        </p>
        <v-alert
          type="warning"
          class="mt-2"
          style="font-size: 12px;"
        >
          <strong>Note:</strong> Fuzzy matching is not perfect and should always be reviewed for accuracy.
        </v-alert>
      </v-col>
    </v-row>

    <v-divider class="my-6" />

    <v-row class="pa-0 py-4 flex-nowrap">
      <v-col cols="12">
        <div class="import-headline py-2">
          Import File
        </div>
        <div class="import-subheadline pb-0">
          The file provided must be a CSV. To import successfully, please
          <a href="#" @click="downloadTemplate">download our template</a>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-start flex-wrap pt-2">
        <v-btn
          class="custom-button custom-button--blue px-11 mr-sm-5 mb-4 mb-sm-0"
          style="min-width: 150px"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="selectFile"
        >
          {{ uploadFileName ? `File: ${uploadFileName}` : "Choose File" }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4">
        <div>
          <v-checkbox
            v-model="performFuzzyMatching"
            :false-value="false"
            class="mt-5"
            label="Perform Fuzzy Matching"
          />
        </div>
      </v-col>
    </v-row>

    <v-divider class="mt-5 mb-6" />

    <v-row class="pa-0 flex-wrap">
      <v-col
        cols="12"
        class="d-flex justify-center justify-sm-end align-center flex-wrap"
      >
        <div v-if="uploadPending" class="add-contact-progress pr-sm-7">
          Processing file (0/0)
        </div>
        <div
          v-else-if="uploadError"
          class="add-contact-progress add-contact-progress--error pr-sm-7"
        >
          The supplied file was invalid
        </div>
        <v-btn
          class="custom-button my-4 px-14"
          :class="uploadPending ? 'custom-button--gray' : 'custom-button--blue'"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="onImport"
        >
          Import
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="currentImports.length" class="field__header mb-4">
      Active Imports
    </div>
    <ImportsTable
      v-if="currentImports.length"
      :imports="currentImports"
    />

    <div class="field__header mt-10 mb-4">
      Previous Imports
    </div>
    <ImportsTable
      :imports="previousImports"
      :for-current-imports="false"
    />
    <PaginationButtons
      :current-page-number="importsPageNumber"
      :current-page-size="importsPageSize"
      :count-total="importsTotal"
      :page-sizes="[25, 50, 100]"
      @next-page="getImportsNextPage"
      @prev-page="getImportsPrevPage"
      @change-page-size="importsChangePageSize"
    />

    <v-dialog v-model="successDialog" scrollable max-width="315px">
      <SuccessfulImportAlert
        v-if="successDialog"
        :number_of_contacts="numberOfImportedRows"
        :message="successMessage"
        @dismiss="successDialog = false"
      />
    </v-dialog>

    <input
      ref="fileUpload"
      type="file"
      class="d-none"
      accept=".csv"
      @change="onFileSelected"
    >
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import SuccessfulImportAlert from "@/views/Contacts/components/AddContact/SuccessfulImportAlert";
import ImportsTable from "@/views/Imports/components/ImportsTable";
import PaginationButtons from "@/sharedComponents/pagination/PaginationButtons";

export default {
  name: "ContactMatchTool",
  components: {
    ImportsTable,
    PageHeader,
    SuccessfulImportAlert,
    PaginationButtons
  },
  data() {
    return {
      performFuzzyMatching: false,
      successDialog: false,
      successMessage: '',
      numberOfImportedRows: null,
      uploadPending: false,
      uploadError: false,
      uploadFileName: null,
      file: null,
      importedFiles: [],
      previousImports: [],
      currentImports: [],
      completedStatuses: ['completed', 'canceled', 'failed'],
      importsPageNumber: 1,
      importsPageSize: 25,
      importsTotal: 0,
      importType: 'contact_match',
    };
  },
  async created(){
    await this.getPreviousImports();
    await this.getCurrentImports();

    this.eventSource = await this.$store.dispatch('mercure/import_type_subscribe', this.importType);
    if (!this.eventSource) {
      return;
    }

    this.eventSource.onmessage = (msg) => {
      this.$store.dispatch('mercure/update_import_type_last_event', {
        importType: this.importType,
        lastEventId: msg.lastEventId
      });

      const parsedMessage = JSON.parse(msg.data);

      // only allowed contact type here
      if (parsedMessage.importType !== this.importType) {
        return;
      }

      const currentImportIndex = this.currentImports.findIndex(elem => elem.id === parsedMessage.id) ?? null;
      if (currentImportIndex === -1) {
        this.currentImports.unshift(parsedMessage);
        return;
      }

      // has error
      if (parsedMessage.status === 'requires action') {
        this.$store.commit('snackbar/showMessage', {
          color: 'warning',
          content: 'An import has actions need to be solved before continuing, please check.'
        });
      }

      if (this.completedStatuses.includes(parsedMessage.status)) {
        this.$delete(this.currentImports, currentImportIndex);
        this.getPreviousImports();

        this.$store.commit('snackbar/showMessage', {
          color: "success",
          content: `An import has completed. Please check the section below for the results.`
        })
        return;
      }

      this.$set(this.currentImports, currentImportIndex, parsedMessage);
    };
  },
  beforeDestroy() {
    if (!this.eventSource) {
      return;
    }
    this.$store.dispatch('mercure/unsubscribe', this.eventSource)
  },
  methods: {
    selectFile() {
      this.$refs.fileUpload.click();
    },
    /**
     * On File Selected
     * @param {{target: {files: FileList}}} event
     */
    onFileSelected(event) {
      const file = event.target.files[0];
      this.file = file;
      this.uploadFileName = file.name;
    },
    async onImport() {
      if (!this.file) {
        return this.$store.commit('snackbar/showMessage', {
          'content': 'Please select a CSV file before continue.',
          'color': 'error',
        });
      }

      try {
        const formData = new FormData();
        formData.append('file', this.file);
        formData.append('importType', this.importType);
        if (this.performFuzzyMatching) {
          formData.append('importData[performFuzzyMatching]', 'true');
        }

        const { data } = await this.$rest.imports.post_resource(formData);

        this.postUploadStatusHandler(data);
      } catch (e) {
        const errorResponse = e.response?.data ?? null;
        if (errorResponse) {
          const firstError = errorResponse.errors?.[0] ?? null;

          if (firstError) {
            return this.$store.commit('snackbar/showMessage', {
              'content': firstError.error,
              'color': 'error',
            });
          }
        }

        return this.$store.commit('snackbar/showMessage', {
          'content': 'Error while trying to upload the file, please try again.',
          'color': 'error',
        });
      }
    },
    downloadTemplate() {
      window.location.replace(
        process.env.VUE_APP_REST_ADDRESS + `imports/${this.$store.getters["user/account"].accountId}/template/${this.importType}`
      );
    },
    /**
     * After uploaded file, handle some specific cases based on the message
     * @param data
     * @returns {*}
     */
    postUploadStatusHandler(data) {
      if (data.status === 'failed') {
        return this.$store.commit('snackbar/showMessage', {
          'content': 'Failed to process this file. Please try again.',
          'color': 'error',
        });
      }

      this.successMessage = 'Your file has been added into the Queue.';
      this.successDialog = true;

      this.file = null;
      this.uploadFileName = null;
      this.$refs.fileUpload.value = null;

      this.getCurrentImports();
    },
    async getPreviousImports() {
      const resp = await this.$rest.imports.get_collection({
        limit: this.importsPageSize,
        page: this.importsPageNumber,
        status: this.completedStatuses,
        sort: ['updatedAt:desc'],
        importType: this.importType,
      });

      this.previousImports = resp.data.items;
      this.importsTotal = resp.data.totalCount;
    },
    async getImportsNextPage() {
      this.importsPageNumber += 1;
      await this.getPreviousImports();
    },
    async getImportsPrevPage() {
      this.importsPageNumber -= 1;
      await this.getPreviousImports();
    },
    async importsChangePageSize(size) {
      this.importsPageSize = size;
      this.importsPageNumber = 1;
      await this.getPreviousImports();
    },
    async getCurrentImports() {
      const resp = await this.$rest.imports.get_collection({
        status: ['queued', 'preparing', 'analyzing', 'requires action', 'processing', 'finalizing'],
        sort: ['createdAt:desc'],
        importType: this.importType,
      });

      this.currentImports = resp.data.items;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-contact-progress {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #66788e;
  &--error {
    color: #dc2929;
  }
}

.page-header-block {
  margin-bottom: 45px;
}

::v-deep .field-header, .import-headline {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #241c15;
}

.import-subheadline {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #66788e;
}
</style>
